const THREE = require('three');

import mat from '../material/index.js'

export default function (color) {

  this.getFlower = function () {
    const geometry = new THREE.CylinderGeometry( .4, .2, .4, 8 )
    const circle = new THREE.Mesh( geometry, new mat( { color: color, shininess: 60, specular: 0xffffff } ) );
    circle.receiveShadow = true;
    return circle
  }

  this.getTop = function () {
    const flow = new THREE.Group()
    const flower = this.getFlower()
    const flower2 = this.getFlower()
    flower2.position.setZ(0)
    flower2.position.setY(.1)
    flower2.scale.y = .8
    flower2.scale.z = .8
    flower2.scale.x = .8
    const flower3 = this.getFlower()
    flower3.position.setZ(0)
    flower3.position.setY(.2)
    flower3.scale.y = .7
    flower3.scale.z = .7
    flower3.scale.x = .7
    flower.add(flower2)
    flower.add(flower3)
    flow.add(flower)
    flow.add(this.addLight())
    return flow
  }

  this.addLight = function () {
    const intensity = .3
    const light = new THREE.PointLight(color, intensity);

    light.position.set(0, 0, -.1);
    light.position.setZ(.0)
    light.castShadow = true
    light.receiveShadow = true
    return light
  }

  this.getBottom = function () {
    const geometry = new THREE.CylinderGeometry( .1, .02, 1.2, 10 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0x00dd00, shininess: 60, specular: 0xffffff } ) );
    circle.receiveShadow = true;
    circle.position.setY(-.6)
    return circle
  }
  this.getGreen = function () {
    const geometry = new THREE.CylinderGeometry( .3, .3, .1, 10 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0x00dd00, shininess: 60, specular: 0xffffff } ) );
    circle.receiveShadow = true;
    circle.position.setY(-.2)
    return circle
  }
  const flow = new THREE.Group()
  flow.add(this.getTop())
  flow.add(this.getBottom())
  flow.add(this.getGreen())

  return flow
}
