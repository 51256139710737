const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color } );

  this.getHair = function (X, Y, Z) {
    const geometry = new THREE.SphereGeometry( .04 )
    const circle = new THREE.Mesh( geometry, material );
    circle.scale.x = -1
    circle.position.setX(X)
    circle.position.setY(Y)
    circle.position.setZ(Z)
    return circle
  }

  this.getHair2 = function () {
    const geometry = new THREE.SphereGeometry( .51 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.09)
    circle.position.setZ(-.05)
    return circle
  }

  const group = new THREE.Group();
  group.name="hair";

  group.add(this.getHair2())

  for (let i = 0; i<28; i+=2) {
    group.add(this.getHair(0, (1.33 + (0.008 * i)), (0.42 - (0.01 * i))))

    group.add(this.getHair(0.05, (1.33 + (0.008 * i)), (0.42 - (0.01 * i))))
    group.add(this.getHair(-0.05, (1.33 + (0.008 * i)), (0.42 - (0.01 * i))))

    group.add(this.getHair(-0.1, (1.32 + (0.008 * i)), (0.42 - (0.01 * i))))
    group.add(this.getHair(0.1, (1.32 + (0.008 * i)), (0.42 - (0.01 * i))))

    group.add(this.getHair(0.15, (1.31 + (0.008 * i)), (0.42 - (0.01 * i))))
    group.add(this.getHair(-0.15, (1.31 + (0.008 * i)), (0.42 - (0.01 * i))))

    group.add(this.getHair(0.2, (1.29 + (0.008 * i)), (0.42 - (0.01 * i))))
    group.add(this.getHair(-0.2, (1.29 + (0.008 * i)), (0.42 - (0.01 * i))))

    group.add(this.getHair(0.25, (1.27 + (0.008 * i)), (0.42 - (0.01 * i))))
    group.add(this.getHair(-0.25, (1.27 + (0.008 * i)), (0.42 - (0.01 * i))))

    group.add(this.getHair(0.30, (1.24 + (0.008 * i)), (0.42 - (0.01 * i))))
    group.add(this.getHair(-0.30, (1.24 + (0.008 * i)), (0.42 - (0.01 * i))))
  }

  return group

}
