const THREE = require('three');
import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color } );


  this.getHair = function () {
    const geometry = new THREE.SphereGeometry( .45 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.15)
    circle.position.setX(-.07)
    circle.position.setZ(-.05)
    return circle
  }
  this.getHair2 = function () {
    const hair = this.getHair()
    hair.position.setX(.07)
    hair.scale.y = -1
    return hair
  }

  this.getHair3 = function () {
    const geometry = new THREE.SphereGeometry( .52 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.1)
    circle.position.setX(0)
    circle.position.setZ(-.1)
    return circle
  }

  this.getHair4 = function () {
    const geometry = new THREE.CylinderGeometry( .05, .6, .6, 8 )
    const circle = new THREE.Mesh( geometry, material );
    circle.rotateX(-.8)
    circle.position.setY(1)
    circle.position.setZ(-0.35)
    return circle
  }



  const group = new THREE.Group();
  group.name="hair";
  group.add(this.getHair())
  group.add(this.getHair2())
  group.add(this.getHair3())
  group.add(this.getHair4())
  return group
}
