const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color, transparent: true, opacity: 1 } );

  this.getHair = function () {
    const geometry = new THREE.BoxGeometry( settings.width, settings.width, (settings.width * 2) )
    const circle = new THREE.Mesh( geometry, material );
    circle.rotateX(1)
    circle.position.setY(.75)
    circle.position.setZ(0.25)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }

  const group = new THREE.Group();
  group.name="beard";

  group.add(this.getHair())

  return group

}
