const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings) {
  this.mouth = function () {
    var pts = [];
    var numPoints = 90;
    var radius = .15;

    for ( let i = 0; i < numPoints; i ++ ) {

        var a = Math.PI * i / numPoints;
        pts.push( new THREE.Vector2 ( Math.cos( a ) * radius, Math.sin( a ) * radius ) );

    }

    // shape to extrude
    var shape = new THREE.Shape( pts );

    // extrude options
    var options = {
        amount: .35,              // default 100, only used when path is null
        bevelEnabled: false,
        bevelSegments: .1,
        steps: 1
    };

    // geometry
    var geometry = new THREE.ExtrudeGeometry( shape, options );

    // mesh
    let mesh = new THREE.Mesh(
        geometry,
        new mat( { color: settings.color, wireframe: false } )
    );
    mesh.position.setZ(.08)
    mesh.position.setY(.90)
    mesh.scale.x = -1
    mesh.rotateZ(3.1)
    return mesh
  }
  return this.mouth()
}
