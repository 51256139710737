export default function blink (fig) {
  this.interval = false
  this.interval2 = false
  this.visible = false
  this.blinkTime = 400
  this.timeout = 8000

  this.animate = function (){

    this.blink()
  }

  this.blink = function () {
    this.visible = !this.visible
    fig.visible = this.visible;

    if (this.interval) {
      //
      // clearInterval(this.interval2)
    }

    this.interval = setTimeout(function () {
      this.interval2 = setTimeout(function () {
        this.visible = !this.visible
        fig.visible = this.visible;
      }.bind(this), this.blinkTime)
      this.blink()
    }.bind(this), this.timeout)
  }
}
