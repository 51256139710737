<script>
import scene1 from './Scene_1'
import threeFigure from '@/assets/scene/builder.js';
export default {
  extends: scene1,
  data () {
    return {
      mode: 'day',
      figure: 'jens',
      menu: false
    }
  },
  methods: {
    loadFigure (name) {
      this.animation = new threeFigure(this.$refs.three, name);
      this.animation.getPreset()
      this.animation.getCamera()
      this.animation.getRenderer()
      this.animation.run(this.mode)
      this.figure = name
    }
  }
}
</script>
<style lang="sass">
</style>
