import amazed from './parts/eyes/amazed.js'

export default function (eyes, colors){
  switch (eyes.type) {
    case 'amazed':
      this.eyes = new amazed(eyes, colors)
    break;
  }
  return this.eyes
}
