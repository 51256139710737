const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings) {
  const material = new mat( { color: settings.color, transparent: true, opacity: 1 } );

  this.first = function () {
    const radius = 5;
    const tubeRadius = .8;
    const radialSegments = 3;
    const tubularSegments = 32;
    const geometry = new THREE.TorusBufferGeometry(radius, tubeRadius, radialSegments, tubularSegments);

    const circle = new THREE.Mesh( geometry, material );
    circle.scale.x = -.03
    circle.scale.y = -.03
    circle.scale.z = -.05
    circle.position.setY(1.02)
    circle.position.setX(-.13)
    circle.position.setZ(.56)
    circle.castShadow = true;
    circle.receiveShadow = true;

    return circle
  }
  this.second = function () {
    const material = new mat( { color: 0xffffff, transparent: true, opacity: .4 } );
    const geometry = new THREE.CircleBufferGeometry( .14, 32 )
    const circle = new THREE.Mesh( geometry, material );

    circle.position.setY(1.02)
    circle.position.setX(-.13)
    circle.position.setZ(.58)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }
  this.third = function () {
    const circle = this.first()

    circle.position.setY(1.14)
    circle.position.setX(-.15)
    circle.position.setZ(.5)
    return circle
  }
  this.fourth = function () {
    const circle = this.second()

    circle.position.setY(1.01)
    circle.position.setX(0)
    circle.position.setZ(.5)
    return circle
  }

  this.borrow = function () {
    const geometry = new THREE.BoxGeometry( .04, .05, .5 )
    const circle = new THREE.Mesh( geometry, material );
    circle.rotateY(.45)
    circle.position.setY(1.04)
    circle.position.setX(-.4)
    circle.position.setZ(.35)
    return circle
  }

  this.noise = function () {
    const geometry = new THREE.BoxGeometry( .07, .05, .05 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.06)
    circle.position.setX(0)
    circle.position.setZ(.6)
    return circle
  }

  this.getLeftGlass = function () {

    const group = new THREE.Group();
    group.name = 'glasses';
    group.add(this.first())
    group.add(this.second())

    group.add(this.borrow())
    group.position.setX(0.08)
    group.position.setY(-0.01)
    group.position.setZ(0.05)
    group.scale.x = 1.1
    group.scale.y = 1.05
    group.rotateY(-.2)
    return group
  }

  this.getRightGlass = function () {
    const circle = this.getLeftGlass()
    circle.scale.x = -1
    circle.rotateY(.4)
    circle.position.setX(-0.06)
    return circle
  }

  const group = new THREE.Group();
  group.name = 'glasses';
  group.add(this.getLeftGlass())
  group.add(this.getRightGlass())
  group.add(this.noise())
  return group
}
