import def from './index.js'
import figure from './assets/heart.js'
const THREE = require('three');

const replace = function (dom) {
  this.getMan = function () {
    this.man = new figure({color: 0xaaddff})
    this.man.position.set(0, 0, 0)
    this.scene = this.scene.add( this.man )
  }
  this.getRenderer = function () {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true, alpha: true } );
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setSize(dom.clientWidth, 800);
    this.renderer.render( this.scene, this.camera )
  }
  this.getAnimation = function () { this.updateScene() }
}

export default function (dom, preset) {

  const d = new def(dom, preset)
  const rep = new replace(dom, preset)
  Object.keys(new replace(dom, preset)).forEach(function(k) {
    d[k] = rep[k]
  })

  return d
}
