const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings) {
  const material = new mat( { color: settings.color, transparent: true, opacity: 1 } );

  this.glass = function () {
    const geometry = new THREE.BoxGeometry( .3, .24, .1 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0xffffff, transparent: true, opacity: .4, shininess: 100, specular: 0xDAA520 } ) );
    circle.position.setY(1)
    circle.position.setX(-.15)
    circle.position.setZ(.546)
    return circle
  }

  this.first = function () {
    const geometry = new THREE.BoxGeometry( .3, .04, .09 )
    const circle = new THREE.Mesh( geometry, material );

    circle.position.setY(.88)
    circle.position.setX(-.15)
    circle.position.setZ(.55)
    return circle
  }
  this.second = function () {
    const geometry = new THREE.BoxGeometry( .02, .3, .09 )
    const circle = new THREE.Mesh( geometry, material );

    circle.position.setY(1.01)
    circle.position.setX(-.3)
    circle.position.setZ(.55)
    return circle
  }
  this.third = function () {
    const circle = this.first()

    circle.position.setY(1.14)
    circle.position.setX(-.15)
    circle.position.setZ(.55)
    return circle
  }
  this.fourth = function () {
    const circle = this.second()

    circle.position.setY(1.01)
    circle.position.setX(0)
    circle.position.setZ(.55)
    return circle
  }

  this.borrow = function () {
    const geometry = new THREE.BoxGeometry( .05, .05, .4 )
    const circle = new THREE.Mesh( geometry, material );
    circle.rotateY(.5)
    circle.position.setY(1.1)
    circle.position.setX(-.43)
    circle.position.setZ(.35)
    return circle
  }

  this.noise = function () {
    const geometry = new THREE.BoxGeometry( .06, .05, .05 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.04)
    circle.position.setX(0)
    circle.position.setZ(.55)
    return circle
  }

  this.getLeftGlass = function () {

    const group = new THREE.Group();
    group.name = 'glasses';
    group.add(this.first())
    group.add(this.second())
    group.add(this.third())
    group.add(this.fourth())
    group.add(this.borrow())
    group.add(this.glass())
    group.position.setX(0.09)
    group.position.setY(.02)
    group.position.setZ(0.05)
    group.scale.x = 1

    group.rotateY(-.2)
    return group
  }

  this.getRightGlass = function () {
    const circle = this.getLeftGlass()
    circle.scale.x = -1
    circle.rotateY(.4)
    circle.position.setX(-0.07)
    return circle
  }

  const group = new THREE.Group();
  group.name = 'glasses';
  group.add(this.getLeftGlass())
  group.add(this.getRightGlass())
  group.add(this.noise())
  return group
}
