const THREE = require('three');
import hair from './figure/hair.js'
import eyebrow from './figure/eyebrow.js'
import beard from './figure/beard.js'
import hat from './figure/hat.js'
import cloth from './figure/cloth.js'
import mouth from './figure/mouth.js'
import eyes from './figure/eyes.js'
import head from './figure/head.js'
import glasses from './figure/glasses.js'
import noise from './figure/noise.js'

export default function figure (settings){

  this.get = function () {

    const group = new THREE.Group();
    group.name = "figure"
    group.add( new head(settings) )
    group.add( new eyes(settings.eyes, settings.cloth.colors) )
    group.add( new noise(settings.noise) )
    group.add( new cloth(settings.cloth, settings) )
    group.add( new hat(settings.hat) )
    group.add( new hair(settings.hair) )
    group.add( new eyebrow(settings.eyebrow) )
    group.add( new beard(settings.beard) )
    group.add( new mouth(settings.mouth) )
    group.add( new glasses(settings.glasses) )

    if (settings.scale) {
      group.scale.x = settings.scale
      group.scale.y = settings.scale
      group.scale.z = settings.scale
    }
    return group;
  }

}
