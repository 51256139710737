const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings) {

  this.head = function () {
    const geometry = new THREE.SphereGeometry( .5 );
    const material = new mat( { color: settings.cloth.colors.skin, transparent: true, opacity: 1 } );
    const circle = new THREE.Mesh( geometry, material );
    circle.position.set(0,1.05,0)
    circle.castShadow = true
    circle.receiveShadow = true
    return circle
  }

  this.getEar = function () {
    const geometry = new THREE.CylinderGeometry( .08, .12, .05, 32  )
    const material = new mat( { color: settings.cloth.colors.skin } );
    const circle = new THREE.Mesh( geometry, material );
    circle.castShadow = true
    circle.receiveShadow = true
    circle.rotateX(-1.5)
    return circle
  }

  this.getEars = function () {
    const group = new THREE.Group();
    group.name = 'ears';
    const earLeft = this.getEar()
    earLeft.name= 'earLeft';
    earLeft.position.set(-.46,1.05,0.1)
    group.add(earLeft)

    const earRight = this.getEar()
    earRight.name= 'earRight';
    earRight.position.set(.46,1.05,0.1)
    group.add(earRight)
    return group
  }

  const group = new THREE.Group();
  group.name = 'head';
  group.add( this.head() );
  group.add( this.getEars() )
  return group

}
