const THREE = require('three');

import mat from '../../../material/index.js'
import braid from './braid.js'

export default function (settings){

  const material = new mat( { color: settings.color } );

  this.getGround = function () {
    const geometry = new THREE.SphereGeometry( .52 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.1)
    circle.position.setX(0)
    circle.position.setZ(-.1)
    return circle
  }

  this.getHair = function () {
    const b = new braid(settings)
    b.rotateY(46)
    b.scale.x = .8
    b.scale.z = 1
    return b
  }

  this.getFullHair = function () {
    const group = new THREE.Group();
    group.add(this.getHair())

    const hair2 = this.getHair()
    hair2.rotateY(2)
    group.add(hair2)
    group.rotateY(-3)
    return group
  }

  this.getStrand = function () {
    const geometry = new THREE.CylinderGeometry( .2, .4, .2, 32, 4 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.4)
    circle.position.setX(0)
    circle.position.setZ(.12)
    circle.rotateX(-.05)
    return circle
  }

  const group = new THREE.Group();
  group.name="hair";
  group.add(this.getGround())
  group.add(this.getFullHair())
  group.add(this.getStrand())

  return group

}
