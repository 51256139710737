import afro from './parts/hair/afro.js'
import balding from './parts/hair/balding.js'
import shorn from './parts/hair/shorn.js'
import braid from './parts/hair/braid.js'
import doublebraid from './parts/hair/doublebraid.js'
import neck from './parts/hair/neck.js'
import bubi from './parts/hair/bubi.js'
import strands from './parts/hair/strands.js'

export default function (hair){
  switch (hair.type) {
    case 'afro':
      this.hair = new afro(hair)
    break;
    case 'balding':
      this.hair = new balding(hair)
    break;
    case 'shorn':
      this.hair = new shorn(hair)
    break;
    case 'braid':
      this.hair = new braid(hair)
    break;
    case 'doublebraid':
      this.hair = new doublebraid(hair)
    break;
    case 'neck':
      this.hair = new neck(hair)
    break;
    case 'bubi':
      this.hair = new bubi(hair)
    break;
    case 'strands':
      this.hair = new strands(hair)
    break;
  }
  return this.hair
}
