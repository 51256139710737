import teacher from './parts/glasses/teacher.js'
import rounded from './parts/glasses/rounded.js'
import quarter from './parts/glasses/quarter.js'

export default function (glasses){
  switch (glasses.type) {
    case 'teacher':
      this.glasses = new teacher(glasses)
    break;
    case 'rounded':
      this.glasses = new rounded(glasses)
    break;
    case 'quarter':
      this.glasses = new quarter(glasses)
      break;
  }
  return this.glasses
}
