const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color } );

  this.getHair = function () {
    const geometry = new THREE.SphereGeometry( .4 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.15)
    circle.position.setX(-.15)
    circle.position.setZ(-.05)
    return circle
  }

  this.getHair2 = function () {
    const geometry = new THREE.SphereGeometry( .4 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.15)
    circle.position.setX(.15)
    circle.position.setZ(-.05)
    return circle
  }

  this.getHair3 = function () {
    const geometry = new THREE.SphereGeometry( .47 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.1)
    circle.position.setX(0)
    circle.position.setZ(-.15)
    return circle
  }

  const group = new THREE.Group();
  group.name="hair";

  group.add(this.getHair())
  group.add(this.getHair2())
  group.add(this.getHair3())


  return group

}
