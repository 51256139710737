import dot from './parts/noise/dot.js'

export default function (noise){
  switch (noise.type) {
    case 'dot':
      this.noise = new dot(noise)
    break;
  }
  return this.noise
}
