const THREE = require('three');

import mat from '../material/index.js'

export default function () {

  this.getHeart = function () {
    const x = 0, y = 0;
    const heartShape = new THREE.Shape();
    heartShape.moveTo( x + 5, y + 5 );
    heartShape.bezierCurveTo( x + 5, y + 5, x + 4, y, x, y );
    heartShape.bezierCurveTo( x - 6, y, x - 6, y + 7,x - 6, y + 7 );
    heartShape.bezierCurveTo( x - 6, y + 11, x - 3, y + 15.4, x + 5, y + 19 );
    heartShape.bezierCurveTo( x + 12, y + 15.4, x + 16, y + 11, x + 16, y + 7 );
    heartShape.bezierCurveTo( x + 16, y + 7, x + 16, y, x + 10, y );
    heartShape.bezierCurveTo( x + 7, y, x + 5, y + 5, x + 5, y + 5 );

    const extrudeSettings = {
      steps: 32,
      depth: 1,
      bevelEnabled: 1,
      bevelThickness: 2,
      bevelSize: 2,
      bevelOffset: -.5,
      bevelSegments: 4
    };

    var extrudedGeometry = new THREE.ExtrudeGeometry(heartShape, extrudeSettings);

    const circle = new THREE.Mesh( extrudedGeometry, new mat( { color: 0xff0000, shininess: 60, specular: 0xffffff } ) );
    circle.receiveShadow = true;
    circle.scale.y = -1
    circle.position.set(-5,10,0)
    return circle
  }

  this.addLight = function () {
    const color = 0xff0000
    const intensity = .5
    const light = new THREE.PointLight(color, intensity);

    light.position.set(.15, .4, .7);
    light.scale.x = 1
    light.scale.y = 2

    light.castShadow = true
    light.receiveShadow = true
    return light
  }

  const group = new THREE.Group()
  const heart = this.getHeart()
  group.add(heart)
  group.scale.x = 0.1
  group.scale.y = 0.1
  group.scale.z = 0.1
  group.add(this.addLight())
  return group

}
