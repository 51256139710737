const THREE = require('three');

import round from './round'
import mat from '../../../material/index.js'

export default function (settings) {

  this.cheeks = function () {
    const geometry = new THREE.SphereGeometry( .13 );
    const material = new mat( { color: 0xeeaa99, transparent: true, opacity: 1 } );
    const circle = new THREE.Mesh( geometry, material );
    circle.position.set(.22,.87,.28)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }

  const group = new THREE.Group();
  group.name = 'head';
  group.add( new round(settings) );
  group.add( this.cheeks() );

  const cheekLeft = this.cheeks()
  cheekLeft.position.setX(-.22)
  group.add( cheekLeft );

  return group

}
