import smile from './parts/mouth/smile.js'
import neutral from './parts/mouth/neutral.js'
import laught from './parts/mouth/laught.js'

export default function (mouth){
  switch (mouth.type) {
    case 'smile':
      this.mouth = new smile(mouth)
    break;
    case 'neutral':
      this.mouth = new neutral(mouth)
    break;
    case 'laught':
      this.mouth = new laught(mouth)
    break;
  }
  return this.mouth
}
