const THREE = require('three');
const OrbitControls = require('three-orbit-controls')(THREE)
import figure from './figure'
import animation from './animations/animation.js'
import camera from './camera/camera.js'
import lightning from './lightning/'

import preset from './presets/index'


export default function (dom, settings) {

  this.mousemove = false
  this.scene = new THREE.Scene({alpha: true});

  this.setMousemove = function () {
    if (!this.settings.mousemove) {
      return false
    }

    if (!this.mousemove) {
      this.controls = new OrbitControls( this.camera, this.renderer.domElement );
      this.controls.enablePan = true;
      this.controls.enableZoom = true;
      this.controls.enableDamping = true;
      this.controls.minPolarAngle = 0.8;
      this.controls.maxPolarAngle = 2.4;
      this.controls.dampingFactor = 0.05;
      this.controls.rotateSpeed = .05;
      this.controls.zoomSpeed = .5;
      this.controls.minDistance = 1;
      this.controls.maxDistance = 4;
      this.camera.lookAt( this.man );
      this.camera.position.setZ( 2.5 );
      this.camera.position.setX( 0 );
      this.camera.position.setY( 0.5 );
      this.mousemove = true
    }
    this.man.position.setY(-.5)

    this.controls.update();

  }
  this.getRenderer = function () {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true, alpha: true } );
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setSize(dom.clientWidth, dom.clientWidth);
    this.renderer.render( this.scene, this.camera )
  }
  this.getPreset = function () {
    this.settings = new preset(settings)
  }
  this.getAnimation = function () {
    this.ani = new animation()
    this.ani.moveArms(this.man.getObjectByName('arms'))
    this.ani.blink(this.man.getObjectByName('eyes'))
    this.updateScene()
  }

  this.getCamera = function () {
    const c = new camera(this.settings)
    this.camera = c.createCamera()
  }

  this.updateScene = function () {
    requestAnimationFrame( this.updateScene, 1000 )
    this.renderer.render( this.scene, this.camera )
    this.controls.update()
  }.bind(this)

  this.getMan = function () {
    const fig = new figure(this.settings)
    this.man = fig.get()
    this.scene = this.scene.add( this.man )
  }

  this.getLight = function (mode) {
    const light = new lightning()
    light.scene = this.scene
    light.removeLights()
    light[mode]()
  }


  this.removeMan = function () {
    if (this.scene.getObjectByName('figure')) {
      this.scene.remove(this.scene.getObjectByName('figure'))
    }
  }

  this.setPreset = function (name) {
    settings = name
    this.getPreset(name)
  }

  this.animate = function (direction) {

    this.ani.run = false

    switch(direction) {
      case 'right':
        this.ani.run = true
        this.ani.direction = 'right'
        this.ani.rotateFigure(this.man, this.updateScene)
      break;
      case 'left':
        this.ani.run = true
        this.ani.direction = 'left'
        this.ani.rotateFigure(this.man, this.updateScene)
      break;
      case 'stop':
        this.ani.run = false
      break;
    }
  }



  // this.getPreset()
  // this.getCamera()
  // this.getRenderer()

  this.run = function (mode) {
    this.getMan()
    this.getLight(mode)
    this.setMousemove()
    this.getAnimation()
    dom.innerHTML = '';
    dom.appendChild(this.renderer.domElement);
  }
}
