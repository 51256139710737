<template>
  <component :is="scene" />
</template>

<script>
import s1 from './components/Scene_1.vue'
import s2 from './components/Scene_2.vue'

export default {
  name: 'App',
  data () {
    return {
      scene: 's1'
    }
  },
  components: {
    s1, s2
  }
}
</script>

<style lang="sass">
html, body
  padding: 0
  margin: 0

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
.hello
  display: flex
  align-items: center
  flex-direction: column
  gap: 10px
  min-height: 100vh
  &.day
    background: #eeeeee
    background: -moz-radial-gradient(center, ellipse cover,  #eeeeee 0%, #cccccc 100%)
    background: -webkit-radial-gradient(center, ellipse cover,  #eeeeee 0%,#cccccc 100%)
    background: radial-gradient(ellipse at center,  #eeeeee 0%,#cccccc 100%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=1 )
    .menu
      a
        color: black
  &.night
    background: #b5bdc8
    background: -moz-radial-gradient(center, ellipse cover,  #b5bdc8 0%, #828c95 36%, #28343b 100%)
    background: -webkit-radial-gradient(center, ellipse cover,  #b5bdc8 0%,#828c95 36%,#28343b 100%)
    background: radial-gradient(ellipse at center,  #b5bdc8 0%,#828c95 36%,#28343b 100%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5bdc8', endColorstr='#28343b',GradientType=1 )
    .menu
      a
        color: white
        border-color: white
  div.three
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    canvas
      flex: 1
      max-width: 600px
      max-height: 600px
      display: block
  .menu
    display: flex
    gap: 10px
    a
      color: black
      text-decoration: none
      border: 2px solid black
      font-family: Helvetica
      padding: 3px
</style>
