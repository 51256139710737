const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color, transparent: true, opacity: 1 } );

  this.getHair = function () {
    const geometry = new THREE.BoxGeometry( .2, settings.width, .3 )
    const circle = new THREE.Mesh( geometry, material );
    circle.name = "eyebrowRight"
    circle.rotateX(2.5)
    circle.rotateY(1.2)
    circle.rotateZ(-16)
    circle.position.setY(1.155)
    circle.position.setX(0.15)
    circle.position.setZ(0.38)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }

  this.getHair2 = function () {
    const circle = this.getHair()
    const group = new THREE.Group();
    group.name = "eyebrowLeft"
    group.add(circle)
    group.scale.x = -1
    return group
  }

  const group = new THREE.Group();
  group.name="eyebrows";

  group.add(this.getHair())
  group.add(this.getHair2())

  return group

}
