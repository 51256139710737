const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color, side: THREE.DoubleSide } );

  this.getHair = function () {
    const geometry = new THREE.SphereGeometry( .54, 32, 32, 0, 2*Math.PI, 0, 0.45 * Math.PI )
    const mesh = new THREE.Mesh( geometry, material );
    mesh.position.setY(1.195)
    mesh.position.setZ(-.1)
    mesh.rotateX(-.115)
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh
  }

  this.getHair3 = function () {
    const geometry = new THREE.SphereGeometry( .56, 32, 32, 5, 2*Math.PI, 0, 0.45 * Math.PI )
    const mesh = new THREE.Mesh( geometry, material );
    mesh.position.setY(1.15)
    mesh.position.setZ(-.1)
    mesh.rotateX(-1.2)
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh
  }

  this.getHair4 = function () {
    const geometry = new THREE.SphereGeometry( .53, 32, 32, 5, 2*Math.PI, 0, 0.57 * Math.PI )
    const mesh = new THREE.Mesh( geometry, material );
    mesh.position.setY(1.08)
    mesh.position.setZ(-.09)
    mesh.rotateX(-1)
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh
  }

  this.getHair5 = function () {
    const geometry = new THREE.SphereGeometry( .64, 32, 32, 5, 2*Math.PI, 0, 0.31 * Math.PI )
    const mesh = new THREE.Mesh( geometry, material );
    mesh.position.setY(.88)
    mesh.position.setZ(.3)
    mesh.rotateX(-.11)
    mesh.scale.y = 1.2
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh
  }

  this.getHair2 = function () {
    const geometry = new THREE.RingGeometry( 0, .64, 32 );
    const mesh = new THREE.Mesh( geometry, material );
    mesh.scale.y = 1.1
    mesh.scale.x = .9
    mesh.rotateX(80)
    mesh.position.setZ(.08)
    mesh.position.setY(1.31)
    mesh.castShadow = true;
    return mesh
  }


  const group = new THREE.Group();
  group.name="hat";

  group.add(this.getHair2())
  group.add(this.getHair())
  // group.add(this.getHair3())
  // group.add(this.getHair4())
  group.add(this.getHair5())

  group.scale.x = 1.1
  group.rotateX(-.15)
  group.position.setZ(.18)
  group.position.setY(-.06)
  return group

}
