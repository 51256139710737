import chinStripe from './parts/beard/chinStripe.js'
import full from './parts/beard/full.js'
import mustache from './parts/beard/mustache.js'
import unshaved from './parts/beard/unshaved.js'
import zorro from './parts/beard/zorro.js'

export default function (beard){
  switch (beard.type) {
    case 'chinStripe':
      this.beard = new chinStripe(beard)
    break;
    case 'full':
      this.beard = new full(beard)
    break;
    case 'mustache':
      this.beard = new mustache(beard)
    break;
    case 'unshaved':
      this.beard = new unshaved(beard)
    break;
    case 'zorro':
      this.beard = new zorro(beard)
    break;
  }
  return this.beard
}
