import ina from './ina'
import jens from './jens'
import anne from './anne'
import nora from './nora'

export default function (preset) {
  if (typeof preset === 'object') {
    return preset
  }
  switch(preset) {
    case 'jens':
      this.settings = jens
    break;
    case 'ina':
      this.settings = ina
    break;
    case 'anne':
      this.settings = anne
    break;
    case 'nora':
      this.settings = nora
    break;
  }
  return this.settings
}
