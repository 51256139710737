const THREE = require('three');

import zorro from './zorro.js'
import unshaved from './unshaved.js'

export default function (settings){

  this.getHair = function () {
    return new unshaved(settings)
  }

  this.getHair2 = function () {

    return new zorro(settings)
  }



  const group = new THREE.Group();
  group.name="beard";

  group.add(this.getHair())
  group.add(this.getHair2())

  return group

}
