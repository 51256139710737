export default {
  camera: 'down', // front top down
  hair: { color: 0x8B4513, type: 'bubi'},
  assets: ['book'],
  mousemove: true,
  eyebrow: { color: 0x5c532c, type: 'balk', width: .02 },
  beard: { color: 0x333333, type: '', width: .14 },
  hat: { color: 0x555555, type: '' },
  cloth: { type: 'default', colors: { skin: 0xeebb99, cape: 0x5f5f5f, cloth: 0x58427c } },
  mouth: { type: 'smile', color: 0xFF5F49 },
  eyes: { type: 'amazed', color: 0x6c868f },
  head: { type: 'round' },
  glasses: { type: 'teacher', color: 0x654321 },
  noise: { type: 'default', color: 0xeebb99},
  scale: .95
}
