const THREE = require('three');

export default function () {
  const group = new THREE.Group();
  group.name="light";

  this.removeLights = function () {
    if (this.scene.getObjectByName('light')) {
      this.scene.remove(this.scene.getObjectByName('light'))
    }
  }

  this.night = function () {
    let color = 0x6c868f;
    let intensity = .5;
    let light = new THREE.AmbientLight(color, intensity);

    group.add(light)

    color = 0x0000ff;
    intensity = 0.5;
    light = new THREE.DirectionalLight(color, intensity);
    light.position.set(0, 80, 180);
    light.scale.x = 20
    light.scale.y = 20
    group.add(light)

    color = 0xffffff;
    intensity = .6;
    light = new THREE.SpotLight(color, intensity);
    light.position.set(0, 80, 180);
    light.scale.x = 20
    light.scale.y = 20
    group.add(light)

    this.scene.add(group);
  }
  this.day = function () {
    let color = 0xffffff;
    let intensity = .7;
    let light = new THREE.AmbientLight(color, intensity);

    group.add(light)

    color = 0xFFFFFF;
    intensity = .2;
    light = new THREE.DirectionalLight(color, intensity);
    light.position.set(20, 20, 20);
    light.castShadow = true
    light.receiveShadow = true
    group.add(light)


    color = 0xffffff;
    intensity = .2;
    light = new THREE.SpotLight(color, intensity);
    light.position.set(0, 80, 40);
    light.scale.x = 5
    light.scale.y = 5
    light.castShadow = true
    light.receiveShadow = true
    group.add(light)

    this.scene.add(group)
  }
}
