const THREE = require('three');

// import mat from '../../../material/index.js'

import mustache from './mustache.js'
import chinStripe from './chinStripe.js'

export default function (settings){

  // const material = new mat( { color: settings.color, transparent: true, opacity: 1 } );


  this.getHair = function () {

    return new chinStripe(settings)
  }

  this.getHair2 = function () {

    return new mustache(settings)
  }


  const group = new THREE.Group();
  group.name="beard";

  group.add(this.getHair())
  group.add(this.getHair2())

  return group

}
