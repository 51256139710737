const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color, transparent: true, opacity: 1 } );


  this.getHair2 = function () {
    const geometry = new THREE.CylinderGeometry( 0.09, 0.18, 0.05, 32 )
    const circle = new THREE.Mesh( geometry, material );
    circle.rotateX(.4)
    circle.position.setY(.92)
    circle.position.setZ(0.37)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }


  const group = new THREE.Group();
  group.name="beard";

  group.add(this.getHair2())

  return group

}
