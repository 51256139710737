import def from './parts/cloth/default.js'
import superman from './parts/cloth/superman.js'

export default function (cloth, settings){
  switch (cloth.type) {
    case 'default':
      this.cloth = new def(cloth, settings)
    break;
    case 'superman':
      this.cloth = new superman(cloth, settings)
    break;
  }
  return this.cloth
}
