export default function moveArms (fig) {
  this.interval = false
  this.direction = 0
  this.directionInterval = 0
  this.pos = 0
  this.animate = function () {
    if (this.interval) {
      clearInterval(this.interval)
      clearInterval(this.directionInterval)
    }
    this.interval = setInterval(this.move.bind(this), 100)
    this.directionInterval = setInterval(this.changeDirection.bind(this), 3000)
  }
  this.changeDirection = function () {
    this.direction = !this.direction
  }
  this.move = function () {
    if (!this.direction) {
      this.pos = -0.005
      fig.rotateX(this.pos)
      return false
    }
    this.pos = 0.005
    fig.rotateX(this.pos)
  }
}
