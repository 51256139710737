import round from './parts/head/round.js'
import afraid from './parts/head/afraid.js'

export default function (head){
  switch (head.head.type) {
    case 'round':
      this.head = new round(head)
    break;
    case 'afraid':
      this.head = new afraid(head)
    break;
  }
  return this.head
}
