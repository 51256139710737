const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings) {
  const material = new mat( { color: settings.color, transparent: true, opacity: 1 } );

  this.mouth = function () {
    const geometry = new THREE.BoxGeometry( .28, .04, .25 )
    const circle = new THREE.Mesh( geometry, material );
    circle.name = "eyebrowRight"
    circle.rotateX(-2.7)
    circle.rotateY(0)
    circle.rotateZ(0)
    circle.position.setY(.85)
    circle.position.setX(0)
    circle.position.setZ(0.32)
    return circle
  }
  return this.mouth()
}
