const THREE = require('three');

import mat from '../../../material/index.js'


export default function (settings){

  const material = new mat( { color: settings.color, transparent: true, opacity: .8 } );

  this.getHair = function () {
    const geometry = new THREE.SphereGeometry( .5, 32, 32, 32, 3 );
    const circle = new THREE.Mesh( geometry, material);
    material.side = THREE.DoubleSide;
    circle.position.set(0,1.05,0.03)
    circle.rotateX(1.95)
    circle.scale.y = .95
    circle.rotateY(-.5)
    circle.castShadow = true
    circle.receiveShadow = true
    return circle
  }


  const group = new THREE.Group();
  group.name="beard";

  group.add(this.getHair())

  return group

}
