const THREE = require('three');

import mat from '../material/index.js'

export default function (settings){

  const material = new mat( Object.assign(settings, {shininess: 20, specular: 0xFFFFFF}) );

  this.getBookBase = function () {
    const geometry = new THREE.BoxGeometry( .8, .2, .8, 32 )
    const circle = new THREE.Mesh( geometry, material );
    circle.name="book"
    circle.position.set(0, 0, 1)
    const pages = this.getBookPages()
    circle.add(pages)
    pages.position.setX(.02)
    const booklet = this.getBooklet()
    const booklet2 = this.getBooklet()
    circle.add(booklet)
    booklet.position.setY(.021)
    booklet.position.setZ(-.1)
    circle.add(booklet2)
    booklet2.position.setY(.021)
    booklet2.position.setZ(.005)
    booklet2.scale.z = .5
    circle.receiveShadow = true;
    circle.material.side = THREE.DoubleSide
    return circle
  }

  this.getBooklet = function () {
    const geometry = new THREE.BoxGeometry( .6, .16, .07, 32 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0x333333, shininess: 100, specular: 0xDAA520 } ) );
    circle.receiveShadow = true;
    circle.material.side = THREE.DoubleSide
    return circle
  }

  this.getBookPages = function () {
    const geometry = new THREE.BoxGeometry( .761, .16, .81, 32 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0xFFFFFF } ) );
    circle.receiveShadow = true;
    circle.material.side = THREE.DoubleSide
    return circle
  }

  const group = new THREE.Group()
  group.add(this.getBookBase())

  return group

}
