const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color, transparent: true, opacity: .5 } );

  this.getHair = function () {
    const geometry = new THREE.SphereGeometry( .5 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1.08)
    circle.position.setX(0)
    circle.position.setZ(-0.014)
    return circle
  }


  const group = new THREE.Group();
  group.name="hair";

  group.add(this.getHair())


  return group

}
