export default {
  camera: 'front', // front top down
  hair: { color: 0xfcf799, accent: 0x00ff00, type: 'doublebraid', variant: 'short'},
  assets: ['heart'],
  mousemove: true,
  eyebrow: { color: 0xe6d47a, type: 'balk', width: .01 },
  beard: { color: 0x333333, type: '', width: .14 },
  hat: { color: 0x00ff00, type: '' },
  cloth: { type: 'default', colors: { skin: 0xdba787, cape: 0x2d7553, cloth: 0x42bd84 } },
  mouth: { type: 'smile', color: 0xFF5F49 },
  eyes: { type: 'amazed', color: 0x6c868f },
  head: { type: 'afraid' },
  glasses: { type: '', color: 0x654321 },
  noise: { type: 'default', color: 0xeebb99},
  scale: .8
}
