import topCam from './variants/topCam'
import downCam from './variants/downCam'
import frontCam from './variants/frontCam'

export default function camera (settings) {
  this.set = function (cam) {
    switch (cam) {
      case 'top':
      this.camera = new topCam()
      break;
      case 'down':
      this.camera = new downCam()
      break;
      case 'front':
      this.camera = new frontCam()
      break;
    }
  }
  this.createCamera = function () {
    this.set(settings.camera)
    return this.camera
  }
}
