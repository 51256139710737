export default {
  camera: 'front', // front top down
  hair: { color: 0xa5974d, type: 'braid'},
  assets: ['coin'],
  mousemove: true,
  eyebrow: { color: 0xe6d47a, type: 'balk', width: .01 },
  beard: { color: 0x333333, type: '', width: .14 },
  hat: { color: 0x555555, type: '' },
  cloth: { type: 'default', colors: { skin: 0xeebb99, cape: 0xaa00cc, cloth: 0xcc00cc } },
  mouth: { type: 'smile', color: 0xFF5F49 },
  eyes: { type: 'amazed', color: 0x6c868f },
  head: { type: 'afraid' },
  glasses: { type: '', color: 0x333333 },
  noise: { type: 'default', color: 0xeebb99},
  scale: .9
}
