const THREE = require('three');

import mat from '../../../material/index.js'
import laptop from '../../../assets/laptop.js'
import coin from '../../../assets/coin.js'
import book from '../../../assets/book.js'
import flowers from '../../../assets/flowers.js'
import heart from '../../../assets/heart.js'

export default function (settings, mainSettings) {

  this.bottom = function () {
    const geometry = new THREE.CylinderGeometry( .9, .9, .11, 8 )
    const material = new mat( { color: settings.colors.cape } );
    const circle = new THREE.Mesh( geometry, material );
    circle.scale.x = -1
    circle.position.setY(-.3)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }

  this.cape = function () {
    const geometry = new THREE.CylinderGeometry( 0, .75, .15, 6  )
    const material = new mat( { color: settings.colors.cape } );
    const circle = new THREE.Mesh( geometry, material );
    circle.rotateX(-.5)
    circle.scale.x = .6
    circle.position.setY(.32)
    circle.position.setZ(-.52)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }

  this.kragen = function () {
    const geometry = new THREE.CylinderGeometry( .25, .25, .2, 32  )
    const material = new mat( { color: settings.colors.cape } );
    const circle = new THREE.Mesh( geometry, material );
    circle.scale.x = -1
    circle.position.setY(.48)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }

  this.body = function () {

    // const loader = new THREE.TextureLoader()
    // const materials = [
      // new mat({color: 0xff00ff, map: loader.load('https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')}),
    // ];

    const geometry = new THREE.CylinderGeometry( 0, .8, 1.1, 8  )
    const material = new mat( { color: settings.colors.cloth, transparent: true, opacity: 1 } );
    const cube = new THREE.Mesh( geometry, material )
    cube.position.set(0,.30,0)

    cube.castShadow = true;
    cube.receiveShadow = true;

    const group = new THREE.Group()
    group.add(cube)
    group.add( this.bottom() );
    group.add( this.kragen() );
    return group
  }

  this.getArm = function () {
    const geometry = new THREE.SphereGeometry( .15 );
    const material = new mat( { color: settings.colors.skin, transparent: true, opacity: 1 } );
    const circle = new THREE.Mesh( geometry, material );
    circle.castShadow = true;
    circle.receiveShadow = true;

    const geometry2 = new THREE.SphereGeometry( .18 );
    const material2 = new mat( { color: settings.colors.cloth, transparent: true, opacity: 1 } );
    const circle2 = new THREE.Mesh( geometry2, material2 );
    circle2.position.setY(-.07)
    circle2.position.setZ(-.15)
    circle2.castShadow = true;
    circle2.receiveShadow = true;

    const geometry3 = new THREE.SphereGeometry( .2 );
    const material3 = new mat( { color: settings.colors.cloth, transparent: true, opacity: 1 } );
    const circle3 = new THREE.Mesh( geometry3, material3 );
    circle3.position.setY(-.07)
    circle3.position.setZ(-.4)
    circle3.castShadow = true;
    circle3.receiveShadow = true;
    const arm = new THREE.Group();
    arm.add(circle)
    arm.add(circle2)
    arm.add(circle3)
    arm.add(this.getHand())

    return arm
  }

  this.getHand = function () {
    let geometry = new THREE.CircleGeometry( .15, 32 );
    geometry = new THREE.CylinderGeometry( .21, .17, .12, 32  )
    const material = new mat( { color: settings.colors.skin } );
    const circle = new THREE.Mesh( geometry, material );
    circle.position.set(0,.07,.1)
    circle.rotateX(.5)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }

  this.getArms = function () {

    const arms = new THREE.Group();
    arms.name = 'arms'
    const rightArm = this.getArm()
    rightArm.position.set(0.5,.35,0.4)
    const leftArm = this.getArm()
    leftArm.position.set(-0.5,.35,0.4)
    arms.add(rightArm)
    arms.add(leftArm)
    arms.add( this.getHeart() );
    arms.add( this.getLaptop() );
    arms.add( this.getCoin() );
    arms.add( this.getBook() );
    arms.add( this.getFlowers() );
    return arms

  }

  this.getHeart = function () {
    if (!mainSettings.assets || !mainSettings.assets.includes('heart')) {
      return ''
    }
    const h = new heart()
    h.position.set(.6,.4,.7)
    h.rotateX(-.9)
    h.rotateZ(-.9)
    h.scale.x = .04
    h.scale.y = .04
    h.scale.z = .04
    return h
  }

  this.getLaptop = function () {
    if (!mainSettings.assets || !mainSettings.assets.includes('laptop')) {
      return ''
    }
    const myLaptop = new laptop()
    myLaptop.position.setY(-.15)
    myLaptop.position.setZ(0)
    myLaptop.position.setX(-1.2)
    myLaptop.rotateY(.8)
    myLaptop.rotateZ(.25)
    myLaptop.scale.x = .95
    myLaptop.scale.y = .95
    myLaptop.scale.z = .95
    return myLaptop
  }

  this.getFlowers = function () {
    if (!mainSettings.assets || !mainSettings.assets.includes('flowers')) {
      return ''
    }
    const group = new THREE.Group()
    const flower1 = new flowers(0x935860)
    const flower2 = new flowers(0xb76c77)
    const flower3 = new flowers(0x492c30)
    flower1.scale.x = .55
    flower1.scale.y = .4
    flower1.scale.z = .5
    flower1.rotateZ(.3)
    flower1.rotateX(.4)
    flower1.position.setY(.8)
    flower1.position.setX(-.76)
    flower1.position.setZ(.7)
    flower2.rotateZ(-.5)
    flower2.rotateX(.3)
    flower2.position.setX(.7)
    flower2.position.setZ(.3)
    flower2.position.setY(-.7)
    flower3.rotateZ(-.1)
    flower3.rotateX(.4)
    flower3.position.setX(.3)
    flower3.position.setZ(.7)
    flower1.add(flower2)
    flower1.add(flower3)
    group.add(flower1)

    return group
  }

  this.getCoin = function () {
    if (!mainSettings.assets || !mainSettings.assets.includes('coin')) {
      return ''
    }
    const group = new coin()
    group.castShadow = true;
    group.receiveShadow = true;
    group.rotateX(1.3)
    group.rotateY(.2)
    group.rotateZ(-.8)
    group.position.setY(1.25)
    group.position.setX(0)
    group.position.setZ(.65)
    group.scale.x = .7
    group.scale.y = .7
    group.scale.z = .7
    return group
  }

  this.getBook = function () {
    if (!mainSettings.assets || !mainSettings.assets.includes('book')) {
      return ''
    }
    const group = new THREE.Group()
    const book1 = new book({color: 0x0000FF})
    const book2 = new book({color: 0xFF0000})
    const book3 = new book({color: 0x00FF00})
    book1.scale.x = .9
    book1.scale.y = .9
    book1.scale.z = .9
    book1.rotateX(.45)
    book1.rotateY(-.4)
    book1.position.setZ(.1)
    book1.position.setX(.9)
    book1.position.setY(.78)
    book2.position.setY(.2)
    book2.position.setX(.4)
    book2.rotateY(-.4)
    book3.position.setY(.4)
    book3.position.setX(-.50)
    book3.scale.x = .8
    book3.rotateY(.6)
    book1.add(book2)
    book1.add(book3)
    group.add(book1)
    group.rotateY(.3)
    group.position.setX(-.1)
    group.position.setZ(.32)
    group.position.setY(0)
    group.scale.x = 0.9
    group.scale.y = 0.9
    group.scale.z = 0.9
    return group
  }

  const group = new THREE.Group();
  group.add( this.body() );
  group.add( this.getArms() );
  return group
}
