export default {
  camera: 'front', // front top down
  hair: { color: 0xffff00, type: 'balding'},
  assets: ['laptop'],
  mousemove: true,
  eyebrow: { color: 0x333333, type: 'balk', width: .04 },
  beard: { color: 0x333333, type: 'chinStripe', width: .14 },
  hat: { color: 0x555555, type: 'flatcap' },
  cloth: { type: 'default', colors: { skin: 0xeebb99, cape: 0x555555, cloth: 0x425262 } },
  mouth: { type: 'smile', color: 0xFF5F49 },
  eyes: { type: 'amazed', color: 0x6c868f },
  head: { type: 'round' },
  glasses: { type: '', color: 0x333333 },
  noise: { type: '', color: 0xeebb99}
}
