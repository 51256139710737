const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings, colors) {

  this.lashesTop = function () {
    const white3 = new THREE.Mesh( new THREE.SphereGeometry( .13, 32, 32, 2, 3 ), new mat( { color: 0x000000, transparent: true, opacity: .8 } ) );
    white3.rotateZ(1.5)
    white3.rotateY(.4)
    white3.position.setZ(0.015)
    white3.position.setY(-0.01)
    white3.castShadow = true;
    white3.receiveShadow = true;
    return white3
  }
  this.lashesBottom = function () {
    const white3 = new THREE.Mesh( new THREE.SphereGeometry( .13, 32, 32, 2.2, 3 ), new mat( { color: 0x000000, transparent: true, opacity:.5 } ) );
    white3.rotateZ(-1.5)
    white3.rotateY(.4)
    white3.position.setZ(0.01)
    white3.position.setY(0.008)
    white3.castShadow = true;
    white3.receiveShadow = true;
    return white3
  }

  this.eye = function () {
    const white = new THREE.Mesh( new THREE.SphereGeometry( .13 ), new mat( { color: 0xffffff, transparent: true, opacity: 1 } ) );
    const pupil = new THREE.Mesh( new THREE.CircleGeometry( .04, 32 ), new mat( { color: 0x000000, transparent: true, opacity: 1 } ) );
    const color = new THREE.Mesh( new THREE.CircleGeometry( .07, 32 ), new mat( { color: settings.color, transparent: true, opacity: 1 } ) );
    const highlight = new THREE.Mesh( new THREE.CircleGeometry( .02, 32 ), new mat( { color: 0xffffff, transparent: true, opacity: 1 } ) );
    const white2 = new THREE.Mesh( new THREE.SphereGeometry( .13, 32, 32, 2, 4.5 ), new mat( { color: colors.skin, transparent: true, opacity: 1 } ) );

    white.receiveShadow = true;
    white.castShadow = true;

    white2.rotateZ(1.5)
    white2.rotateY(.4)

    white.position.setZ(-.01)
    color.position.setZ(.119)
    pupil.position.setZ(.12)

    color.castShadow = true;
    color.receiveShadow = true;
    pupil.castShadow = true;
    pupil.receiveShadow = true;

    highlight.position.setZ(.121)
    highlight.position.setY(0.02)
    highlight.position.setX(0.03)

    const eye = new THREE.Group()
    eye.add(white);
    eye.add(white2);
    eye.add(this.lashesTop());
    eye.add(this.lashesBottom());
    eye.add(color);
    eye.add(pupil);
    eye.add(highlight);
    return eye
  }

  this.getEyes = function () {
    const eyes = new THREE.Group();
    eyes.name="eyes";
    const leftEye = this.eye();
    leftEye.position.set(.14,1.05,.42);
    leftEye.name="leftEye";
    const rightEye = this.eye();
    rightEye.position.set(-.14,1.05,.42);
    eyes.add(leftEye);
    eyes.add(rightEye);
    return eyes;
  }

  return this.getEyes()
}
