const THREE = require('three');

import mat from '../material/index.js'

import blink from '../animations/variants/blink.js'

export default function (){

  const material = new mat( { color: 0x333333, shininess: 100, specular: 0xDAA520 } );

  let screenImg = { color: 0x8888ff }

  screenImg.map = new THREE.TextureLoader().load(require('../material/textures/coding-screen.jpg'))

  screenImg = new mat(screenImg)

  this.animation = function (group) {
    const btn = group.getObjectByName('onOffButton')
    const b = new blink(btn, function () {})
    b.blinkTime = 300
    b.timeout = 600
    b.animate()

    const screenLight = group.getObjectByName('screenLight')
    const b2 = new blink(screenLight, function () {})
    b2.blinkTime = 200
    b2.timeout = 6000
    b2.animate()
    return group
  }

  this.getOnButton = function () {
    const geometry = new THREE.BoxGeometry( .02, .02, .02, 32 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0x00ff00 } ) );
    circle.name = "onOffButton"
    circle.position.setY(1.3)
    circle.position.setZ(.82)
    circle.position.setX(-.32)
    circle.receiveShadow = true;
    return circle
  }

  this.getScreenCase = function () {
    const geometry = new THREE.BoxGeometry( .7, .7, .05 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(1)
    circle.position.setZ(.8)
    circle.castShadow = true;
    circle.receiveShadow = true;
    circle.material.side = THREE.DoubleSide
    return circle
  }

  this.getTastaturCase = function () {
    const geometry = new THREE.BoxGeometry( .7, .05, .7 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setY(.65)
    circle.position.setZ(1.15)
    circle.castShadow = true;
    circle.receiveShadow = true;
    circle.material.side = THREE.DoubleSide
    return circle
  }

  this.getKey = function () {
    const geometry = new THREE.BoxGeometry( .07, .02, .07 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0x888888 } ) );
    return circle
  }

  this.getKeyRow = function () {
    const group = new THREE.Group();
    let key
    for (let i = -.28; i<.3; i+=.08) {
      key = this.getKey()
      key.position.set(i, .68, .95)
      group.add(key);
    }
    return group
  }

  this.getTastatur = function () {
    const group = new THREE.Group();
    group.add(this.getKeyRow());
    let row
    row = this.getKeyRow()
    row.position.setZ(.11)
    group.add(row);
    row = this.getKeyRow()
    row.position.setZ(.20)
    group.add(row);
    row = this.getKeyRow()
    row.position.setZ(.29)
    group.add(row);
    row = this.getKeyRow()
    row.position.setZ(.38)
    group.add(row);

    row = this.getKey()
    row.position.set(0, .68, 1.44)
    row.scale.x = 3
    row.scale.z = 1.5
    group.add(row);
    return group
  }

  this.getScreen = function () {
    const geometry = new THREE.BoxGeometry( .6, .55, .03 )
    const circle = new THREE.Mesh( geometry, new mat( screenImg ) );
    circle.name="screenLight"
    circle.position.set(0, 1, .82)
    circle.add(this.addLight())
    return circle
  }

  this.addLight = function () {
    const color = 0x0088ff
    const intensity = 1
    const light = new THREE.PointLight(color, intensity);

    light.position.set(-.01, 1, 1);
    light.scale.x = 2
    light.scale.y = 2

    light.castShadow = true
    light.receiveShadow = true
    return light
  }

  const group = new THREE.Group();
  group.add( this.getScreenCase() );
  group.add( this.getTastaturCase() );
  group.add( this.getTastatur() );
  group.add( this.getScreen() );
  // group.add( this.addLight() )
  group.add( this.getOnButton() )
  // const sphereSize = .2;
  // const pointLightHelper = new THREE.PointLightHelper( this.addLight(), sphereSize );
  // group.add( pointLightHelper )
  group.castShadow = true;
  group.receiveShadow = true;
  return this.animation(group)

}
