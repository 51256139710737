const THREE = require('three');

import mat from '../../../material/index.js'
import def from './default.js'

export default function (settings, mainSettings) {

  this.cape = function () {
    const geometry = new THREE.CylinderGeometry( 0, .75, .15, 6  )
    const material = new mat( { color: settings.colors.cape } );
    const circle = new THREE.Mesh( geometry, material );
    circle.rotateX(-.5)
    circle.scale.x = .6
    circle.position.setY(.32)
    circle.position.setZ(-.52)
    circle.castShadow = true;
    circle.receiveShadow = true;
    return circle
  }

  this.body = function () {
    const circle = new def(settings, mainSettings)
    return circle
  }

  const group = new THREE.Group();
  group.add( this.body() );
  group.add( this.cape() );
  return group
}
