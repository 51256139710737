const THREE = require('three');
import mat from '../../../material/index.js'
import bubi from './bubi.js'

export default function (settings){

  const material = new mat( { color: settings.color } );

  this.getHair = function () {
    return new bubi(settings)
  }

  this.getStrand = function () {
    const group = new THREE.Group();
    const geometry = new THREE.CylinderGeometry( .04, .09, .6, 8 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setZ(.28)
    circle.position.setY(1.3)
    circle.position.setX(-.3)
    circle.rotateZ(-.8)
    circle.rotateX(-.15)
    circle.scale.x = .5
    group.add(circle)
    return group
  }

  const group = new THREE.Group();
  group.name="hair";
  group.add(this.getHair())
  group.add(this.getStrand())
  const straehne2 = this.getStrand()
  straehne2.scale.x = -1
  group.add(straehne2)
  return group
}
