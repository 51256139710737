const THREE = require('three');
import mat from '../../../material/index.js'

export default function (settings){

  const material = new mat( { color: settings.color } );

  this.getHair2 = function () {
    const group = new THREE.Group();
    const geometry = new THREE.CylinderGeometry( .3, .5, .7, 32, 1 )
    const circle = new THREE.Mesh( geometry, material );
    circle.scale.x = .7
    circle.scale.z = .8
    circle.position.setY(.85)
    circle.position.setX(.25)
    circle.position.setZ(-.3)
    circle.rotateZ(.1)
    circle.rotateY(.9)
    group.add(circle)
    return group
  }

  this.getHair3 = function () {
    const geometry = new THREE.SphereGeometry( .5 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setZ(-.1)
    circle.position.setY(1.1)
    circle.position.setX(.1)
    return circle
  }

  this.getHair4 = function () {
    const circle = this.getHair3()
    circle.position.setX(-.08)
    return circle
  }

  this.getHair5 = function () {
    const geometry = new THREE.SphereGeometry( .5 )
    const circle = new THREE.Mesh( geometry, material );
    circle.position.setZ(-.1)
    circle.position.setY(1.15)
    return circle
  }



  const group = new THREE.Group();
  group.name="hair";
  group.add(this.getHair2())
  const hair2 = this.getHair2()
  hair2.scale.x = -1
  hair2.position.setX(0)
  group.add(hair2)
  group.add(this.getHair3())
  group.add(this.getHair4())
  group.add(this.getHair5())
  return group
}
