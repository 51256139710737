import flatcap from './parts/hat/flatcap.js'
import basecap from './parts/hat/basecap.js'

export default function (hat){
  switch (hat.type) {
    case 'flatcap':
      this.hat = new flatcap(hat)
    break;
    case 'basecap':
      this.hat = new basecap(hat)
    break;
  }
  return this.hat
}
