<template>
  <div class="hello" :class="mode">
    <div ref="three" class="three"></div>
    <div v-if="menu" class="menu">
      <a href="#" @click.prevent="setLeft" @mouseover="startAnimationLeft" @mouseleave="clearInterval">links</a>
      <a href="#" @click.prevent="setStop">stop</a>
      <a href="#" @click.prevent="setRight" @mouseover="startAnimationRight" @mouseleave="clearInterval">rechts</a>
      <a href="#" @click.prevent="loadMode()">{{ translate[mode] }}</a>
    </div>
    <div v-if="menu" class="menu">
      <a href="#" @click.prevent="changeFigure('jens')">Jens</a>
      <a href="#" @click.prevent="changeFigure('ina')">Ina</a>
      <a href="#" @click.prevent="changeFigure('nora')">Nora</a>
      <a href="#" @click.prevent="changeFigure('anne')">Anne</a>
    </div>
  </div>
</template>

<script>
import threeFigure from '@/assets/scene/index.js';

export default {
  data () {
    return {
      translate: {
        day: 'Tag',
        night: 'Nacht'
      },
      animation: false,
      interval: false,
      mode: 'day',
      figure: 'jens',
      menu: true
    }
  },
  mounted () {
    this.loadFigure(this.figure)
  },
  methods: {
    loadMode () {
      if (this.mode === 'day') {
        this.mode = 'night'
      } else {
        this.mode = 'day'
      }
        this.animation.getLight(this.mode)
        // this.loadFigure(this.figure)
    },
    changeFigure (name) {
      this.animation.setPreset(name)
      this.animation.removeMan()
      this.animation.getMan()
      this.animation.setMousemove()
      this.animation.getAnimation()
    },
    loadFigure (name) {
      this.animation = new threeFigure(this.$refs.three, name);
      this.animation.getPreset()
      this.animation.getCamera()
      this.animation.getRenderer()
      this.animation.run(this.mode)
      this.figure = name
    },
    clearInterval () {
      if (this.interval) {
        clearInterval(this.interval)
      }
    },
    startAnimationRight () {
      this.clearInterval()
      this.interval = setInterval(this.setRight, 50)
    },
    startAnimationLeft () {
      this.clearInterval()
      this.interval = setInterval(this.setLeft, 50)
    },
    setRight () {
      this.animation.animate('right');
    },
    setLeft () {
      this.animation.animate('left');
    },
    setStop () {
      this.animation.animate('stop');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
</style>
