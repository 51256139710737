const THREE = require('three');

import mat from '../material/index.js'

export default function (){

  const material = new mat( { color: 0xDAA520, shininess: 60, specular: 0xffffff } );

  this.getCoinBase = function () {
    const geometry = new THREE.CylinderGeometry( .45, .45, .07, 32 )
    const circle = new THREE.Mesh( geometry, material );
    circle.name="coin"
    circle.position.set(.5, 0.3, .88)
    circle.rotateX(.5)
    return circle
  }

  this.getCoinCircle = function () {
    const geometry = new THREE.RingGeometry( .0, .35, 32 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0xFFD700, shininess: 100, specular: 0xDAA520 } ) );
    circle.position.set(.5, .33, .91)
    circle.rotateX(-1.07)
    return circle
  }

  this.getCoin1 = function () {
    let geometry = new THREE.BoxGeometry( .1, .4, .1 )
    const circle = new THREE.Mesh( geometry, new mat( { color: 0xDAA520, shininess: 100, specular: 0xDAA520 } ) );
    circle.position.set(.52, .30, .90)
    circle.rotateX(-1.07)

    geometry = new THREE.BoxGeometry( .1, .2, .1 )
    const circle2 = new THREE.Mesh( geometry, new mat( { color: 0xDAA520, shininess: 100, specular: 0xDAA520 } ) );
    circle2.position.set(-.08, 0.1, 0)
    circle2.rotateZ(-.8)

    circle.add(circle2)
    return circle
  }

  this.addLight = function () {
    const color = 0xDAA520
    const intensity = 1
    const light = new THREE.PointLight(color, intensity);

    light.position.set(.55, .4, .7);
    light.scale.x = 2
    light.scale.y = 2

    light.castShadow = true
    light.receiveShadow = true
    return light
  }

  this.getGroup = function () {
    const group = new THREE.Group();
    group.add( this.getCoinBase() );
    group.add( this.getCoinCircle() );
    const circleBottom = this.getCoinCircle()
    circleBottom.rotateX(3.14)
    circleBottom.position.setY(.27)
    circleBottom.position.setX(.52)
    circleBottom.position.setZ(.85)
    group.add( circleBottom );
    group.add( this.getCoin1() );

    return group
  }

  const group = new THREE.Group()
  group.add(this.getGroup())
  group.add(this.addLight())
  //const sphereSize = .2;
  //const pointLightHelper = new THREE.PointLightHelper( this.addLight(), sphereSize );
  //group.add( pointLightHelper )
  return group

}
