export default function rotateFigure (fig) {
  this.run = true
  this.direction = 'right'
  this.direction_y = 1
  this.y = 0
  this.y_max = .1
  this.y_min = -.1

  this.rotate = function rotateObject(X, Y, Z) {
    fig.rotateX(X)
    fig.rotateY(Y)
    fig.rotateZ(Z)
    return fig
 }

 this.setY = function () {
   switch (this.direction){
    case 'right':
      this.y = 0.1
    break;
    case 'left':
      this.y = -0.1
    break;
    }
 }
 this.timeout = false
 this.animate = function () {
   if (this.timeout) {
     clearTimeout(this.timeout)
   }
   if (!this.run) {
     return false
   }
   this.setY()
   this.rotate(0, this.y, 0)

 }

}
