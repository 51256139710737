import balk from './parts/eyebrow/balk.js'

export default function (eyebrow){
  switch (eyebrow.type) {
    case 'balk':
      this.eyebrow = new balk(eyebrow)
    break;
  }
  return this.eyebrow
}
