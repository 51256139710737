import rotateFigure from './variants/rotate-figure.js'
import moveArms from './variants/moveArms.js'
import blink from './variants/blink.js'

export default function animation () {

  this.rotateFigure = function (fig, callback) {
    const rotate = new rotateFigure(fig, callback)
    rotate.direction = this.direction
    rotate.animate()
  }

  this.moveArms = function (fig, callback) {
    const arms = new moveArms(fig, callback)
    arms.animate()
  }

  this.blink = function (fig, callback) {
    const b = new blink(fig, callback)
    b.animate()
  }

}
