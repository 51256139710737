const THREE = require('three');

import mat from '../../../material/index.js'

export default function (settings) {
  const material = new mat( { color: settings.color, transparent: true, opacity: 1 } );

  this.mouth = function () {
    const geometry = new THREE.SphereGeometry( 0.1, 32 )
    const circle = new THREE.Mesh( geometry, material );
    circle.name = "noise"
    circle.rotateX(2)
    circle.scale.y = 2.5
    circle.scale.x = -.5
    circle.position.setZ(.4)
    circle.position.setY(1.03)
    return circle
  }
  return this.mouth()
}
